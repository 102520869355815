<script>
import Layout from "../../layouts/main.vue";
import { ref } from "vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import InfiniteLoading from "v3-infinite-loading";
import { getExercises } from "@/services/exercise.service";
import "v3-infinite-loading/lib/style.css"; //required if you're not going to override default slots

export default {
  page: {
    title: "Starter",
    meta: [{ name: "description", content: appConfig.description }],
  },
  methods: {
   renderHTML(text) {
    return `<span> ${text} </span>`
   },
   showExercise(id){
        console.log(id);
        window.location.href = "/exercise/"+id;
  },
  },
  data() {
    return {
      title: "Starter",
      items: [
        {
          text: "Pages",
          href: "/",
        },
        {
          text: "Starter",
          active: true,
        },
      ],
    };
  },
  setup(){
    let elements = ref([]);
    let page = 0;
    const load = async $state => {
    console.log("loading...");
    try {
      const response = await getExercises(page);
        const json = await response.json();
        if (json.length < 10){
          elements.value.push(...json);
          $state.complete();
        }else{
          elements.value.push(...json);
          $state.loaded();
        }
        console.log(json);
        page++;
    } catch (e) {
      console.log('failure' + e)
    }
  };

  return {
    load,
    elements,
    page,
  };
  },
  components: {
    Layout,
    PageHeader,
    InfiniteLoading,
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-sm-6 col-xl-3" v-for="element in elements" :key="element.id">
      <div class="card h-100">
          <img class="card-img-top img-fluid" src="@/assets/images/small/img-1.jpg" alt="Card image cap">
          <div class="card-body">
              <h4 class="card-title mb-2">{{ element.name }}</h4>
              <p class="card-text">{{ element.description.substring(0,150)+"[...]" }}.</p>
          </div>
          <div class="card-footer">
            <div class="text-end">
                  <a href="javascript:void(0);" @click.prevent="showExercise(element._id)" class="btn btn-primary">Show</a>
              </div>
          </div>
      </div><!-- end card -->
      </div>
			<infinite-loading @infinite="load"></infinite-loading>

    </div><!-- end row -->
  </Layout>
</template>